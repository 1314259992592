import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../containers/layout'
import tw, { theme, css } from 'twin.macro'
import FeaturedProject from '../components/featured-project'
import ProjectPost from '../components/project-post'
import { Helmet } from 'react-helmet'

const ProjectIndex = ({ data }) => {
  const { edges: posts } = data.allMdx

  const site = data.site
  const siteTitle = site.siteMetadata.title
  const domain = site.siteMetadata.siteUrl

  const desc =
    'Selected projects I‘ve worked on in the past few years. The ones that didn‘t make the cuts are the tactical initiatives, shelved explorations, failed experiments and even, side projects.'

  return (
    <Layout>
      <Helmet
        titleTemplate="%s"
        htmlAttributes={{
          lang: 'en',
        }}
        title={`Projects · ${siteTitle}`}
      >
        <meta name="description" content={desc} />
        <meta name="image" content={`${domain}${data.file.childImageSharp.fluid.src}`} />

        <meta name="twitter:title" content={`Projects · ${siteTitle}`} />
        <meta name="twitter:description" content={desc} />
        <meta
          property="twitter:image"
          content={`${domain}${data.file.childImageSharp.fluid.src}`}
        />
        <meta property="twitter:card" content="summary" />

        <meta name="og:title" content={`Projects · ${siteTitle}`} />
        <meta name="og:description" content={desc} />
        <meta property="og:image" content={`${domain}${data.file.childImageSharp.fluid.src}`} />
        <meta name="og:type" content="website" />
      </Helmet>
      <section tw="w-full">
        <div
          tw="mt-5 pt-5 md:mt-16 md:pt-20 mx-6 md:mx-auto grid grid-cols-1 md:grid-cols-2 font-serif pb-10 md:pb-16 border-b"
          css={{
            width: 'min(1024px, calc(100% - 48px))',
            gridRowGap: 8,
            borderColor: `${theme`borderColor.accent`}`,
          }}
        >
          <h1 tw="text-3xl lg:text-4xl col-span-1 font-semibold">Featured Projects</h1>
          <p tw="col-span-1 text-softer prose prose-lg xl:prose-xl">{desc}</p>
        </div>
      </section>

      <main>
        <div tw="mt-10 md:mt-24">
          {posts && posts.slice(0, 1).map(node => <FeaturedProject {...node} key={node.id} />)}
        </div>

        <div tw="md:w-full">
          <div
            tw="mx-8 md:mx-auto md:grid md:grid-cols-2 md:font-serif md:border-b"
            css={{
              width: 'min(1024px, calc(100% - 48px))',
              gridRowGap: 8,
              borderColor: `${theme`borderColor.accent`}`,
            }}
          ></div>
        </div>

        <div tw="mt-10 md:mx-8 md:mt-16 lg:mt-20 xl:mt-32 mb-24 md:mb-32">
          {posts &&
            posts.slice(1, posts.length).map(node => <ProjectPost {...node} key={node.id} />)}
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectIndex {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "project-post" } } }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            template
            title
            description
            date
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ProjectIndex